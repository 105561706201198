@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 12.5px !important;
}

/* Image Fields */
.RaImageField-image {
    max-width: 300px;
}

.ti44 {
    width: 100%
}

.RaList-main {
    padding-top: 10px;
}

.RaFileInput-dropZone {
    background: #efefef !important;
    border: 1px dashed #AAA !important;
}


.fixedToolbar+.MuiToolbar-root {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    z-index: 2;
}

.RaSidebar-fixed {
    margin-top: -5px;
    height: 100% !important;
    padding-bottom: 50px;
}

.Collapsible__trigger {
    cursor: pointer;
}

.Collapsible {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #EEE;
}

section.RaSimpleFormIterator-form {
    width: 100%;
}

.fullWidth {
    width: 100%;
}

.couponsIterator ul li {
    width: 100%;
    border: 1px dashed #AAA;
    padding: 5px;
    margin-bottom: 30px;
}

.sessionEventTicketsArray ul > li {
    border: 1px dashed #000;
    padding: 5px;
    margin-bottom: 20px;
}

.sessionEventSessionsArray ul > li {
    border: 2px dashed #AAA;
    padding: 10px;
    margin-top: 10px;
}

.RaTranslatableInputsTabContent-root {
    /* height: 120px; */
}